.modal {
  box-shadow: 0px 0px 10px 3px var(--GRAY-SHADOW) !important;
  width: 614px !important;
}
.modal :global(.content h3) {
  font-size: 20px !important;
}
.form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 550px;
}
.form .field {
  align-self: center;
  text-align: center !important;
}
