.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: -25px;
  margin-left: 5px;
  --color: var(--DISABLED-FONT-COLOR);
}

.button :global(.close-button i) {
  margin: 0 !important;
  width: 16px !important;
  height: 16px !important;
}
