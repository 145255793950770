.header {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: var(--GRAY-DARK);
  margin-bottom: 7px;
}

.header .logo {
  display: inline-flex;
  align-items: center;
}
.header .text {
  margin-left: 7px;
  white-space: nowrap;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .collapser {
  margin-left: auto;
  display: inline-flex;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header .collapser :global(.loader.ui) {
  position: static;
  transform: translate(0, 0);
}

.header .collapser :global(.loader.ui):before,
.header .collapser :global(.loader.ui):after {
  margin: 0 !important;
  left: 0;
  top: 0;
}

.header .dropdownIcon {
  transition: transform 200ms;
}

.widget.opened .dropdownIcon {
  transform: rotate(0);
}

.widget.collapsed .dropdownIcon {
  transform: rotate(-90deg);
}

.curtain {
  overflow: auto hidden;
  transition: all 500ms;
}

.widget.collapsed .curtain {
  height: 0;
  overflow: hidden;
}

.content {
  padding: 0 0 7px;
}
.createLead {
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
}
.createLead:hover {
  background: #f0f3f8;
}
