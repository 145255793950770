.title {
  font-size: 20px;
  font-weight: 500;
}

.container {
  padding: 30px 40px;
}
.group {
  padding-top: 24px;
}
.group .title {
  font-size: 16px;
  font-weight: 600;
}
.action {
  padding-top: 24px;
}

.contentContainer {
  border-radius: 10px;
  background-color: var(--WHITE);
}
