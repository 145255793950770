.drawerGrid {
  display: grid !important;
  grid-template-rows: min-content min-content 1fr min-content;
  grid-template-areas: "header" "counter" "filters" "actions";
  height: 100%;
}

.top {
  grid-area: header;
  margin-bottom: 0 !important;
}

.filterGroups {
  grid-area: filters;
  overflow: hidden auto;
  margin: 0 -32px;
  padding: 0 32px;
  border-top: 1px solid #d8d8d8;
}

.counter {
  grid-row: counter;
  margin: 0 -32px;
  padding: 32px 113px 32px 31px;
}

.content {
  margin: 0 32px;
}

.selectedItemCount {
  color: var(--GRAY-MID);
}

.filter :global(ul) {
  list-style: none;
  padding-inline-start: 0 !important;
  margin-block-start: 16px !important;
  margin-block-end: 8px !important;
}

.filter :global(ul > li) {
  display: flex;
  justify-content: space-between;
  padding-bottom: 17px;
}

.filter :global(.item) {
  padding: unset !important;
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 20px 24px 30px;
}

.head {
  margin: 0 !important;
}

.expandIcon {
  --image: url("../../assets/images/icons/expand-more.svg");
  --width: 8.57px;
  --height: 16.29px;
  padding: 3.43px 7.71px 4.29px;
  cursor: pointer;
  background-position: center !important;
  transition: transform 0.5s;
}

.expandIcon:global(.open) {
  transform: rotate(90deg);
}

.actions {
  grid-area: actions;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-top: 30px;
}

.actions :global(.button) {
  margin-right: 14px;
}

.applyButton {
  margin-right: 40px !important;
  padding: 8px 24px !important;
  margin-bottom: 0 !important;
}

.clearButton {
  cursor: pointer;
  font-weight: 500;
  line-height: 17px;
  color: var(--GRAY-MID);
}

.collaboratorFilterSelector {
  background-color: #ccc;
}

:global(.ui.vertical.menu) .collaboratorFilterSelector :global(.menu) {
  margin: 0;
}

:global(.ui.vertical.menu) .collaboratorFilterSelector :global(.menu .item) {
  padding: 0;
  font-size: 14px;
  color: var(--BLACK);
}

:global(.ui.vertical.sidebar.menu)
  .collaboratorFilterSelector
  :global(.menu .staff)
  > * {
  padding-left: 0;
}

.collaboratorInput {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-right: 7px;
  min-height: 34px;
  padding: 0 5px;
  border: 1px solid #ebecf0 !important;
  border-radius: 5px;
  flex-wrap: wrap;
  padding: 8px 8px 0;
  cursor: pointer;
}

.collaboratorInput > div {
  margin-bottom: 8px;
}

.placeholder {
  padding-left: 4px;
  color: var(--GRAY-MID);
}
