.statistics :global(ul) {
  list-style: none !important;
  padding-inline-start: 0px !important;
  margin-block-end: 0px !important;
  margin-block-start: 0px !important;
}

.statistics :global(ul li) {
  list-style: none !important;
}

.title {
  padding-bottom: 21px;
  font-weight: 500;
  line-height: 17px;
}

.blocks {
  display: flex;
  justify-content: flex-start;
}

.block {
  padding: 25px 68.4px 25px 24px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 10px;
  margin-right: 20px;
}

.subTitle {
  color: var(--GRAY-MID);
  line-height: 14px;
  padding-bottom: 7px;
  display: flex;
}

.subTitle :global(span) {
  margin-right: 5px;
}

.subTitle :global(img) {
  cursor: pointer;
}

.count {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.view {
  display: flex;
  align-items: center;
}

.view :global(a) {
  padding-top: 21px;
  cursor: pointer;
  color: var(--PRIMARY-BLUE) !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-decoration: none !important;
}

.view :global(a:link, a:visited) {
  color: var(--PRIMARY-BLUE) !important;
  text-decoration: none !important;
}
.arrowIcon {
  padding-top: 21px;
  display: flex;
  align-items: center;
}
.arrowIcon svg {
  --color: var(--PRIMARY-BLUE);
  transform: rotate(180deg) translateY(-1px);
  margin-left: 5.14px;
  width: 9px;
  height: 15px;
}
