.header {
  min-height: 122px;
  background-color: var(--BLACK);
  color: var(--WHITE);
  margin: 0 -32px;
  padding: 21px 35px;
}

.back {
  display: inline-flex;
  align-items: center;
  position: absolute;
  left: 21px;
  top: 21px;
  cursor: pointer;
  width: 75px;
  height: 30px;
  padding: 7px 14px;
}
.back:hover {
  border-radius: 3px;
  background: #000;
}

.back svg {
  margin-right: 7px;
}

.actionArea {
  position: absolute !important;
  top: 21px;
  right: 21px;
  display: flex;
  justify-content: space-between;
}

.actionArea svg {
  cursor: pointer;
}
.iconContainer:first-child {
  margin-right: 7px;
}
.iconContainer {
  width: 20px;
  height: 20px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.icon {
  --color: var(--WHITE);
}
.iconContainer:last-child .icon {
  margin-left: 2px;
}
.iconContainer:hover {
  background: #000;
}

.backIcon svg {
  width: 9px;
  height: 15px;
  --color: #ffffff;
  display: flex;
}
