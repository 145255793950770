.postList {
  position: relative;
}

.postsWrapper {
  overflow: auto;
  width: 100%;
  height: 400px;
  border-radius: 5px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  padding: 20px;
  margin-top: 14px;
  position: relative;
}

.postsWrapper:global(.noPost) {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noPost {
  color: var(--GRAY-MID);
}

.postsContainer {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  position: relative;
}

.post {
  margin-right: 20px;
  margin-bottom: 15px;
}

.post :nth-child(3n) {
  margin-right: 0;
}

.imageWrapper {
  border-radius: 8px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  position: relative;
  margin-bottom: 14px;
  height: 280px;
}

.imageWrapper :global(.checkbox) {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 1;
}

.imageWrapper :global(.ui.checkbox.radio label:before) {
  border-radius: 50% !important;
}

.imageBox {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--GRAY-MID);
}

.content {
  margin-bottom: 14px;
  line-height: 22.4px;
  text-align: left;
  display: -webkit-box;
  max-width: 240px;
  min-height: 23px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
}

.timestamp {
  color: var(--GRAY-MID);
  line-height: 22.4px;
}

.imageWrapper :global(video) {
  max-width: -webkit-fill-available;
  width: 100%;
  width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
