.stepperWrapper {
  display: flex;
  width: 100%;
  max-width: 450px;
  align-items: flex-end;
  justify-content: space-between;
}

.stepperNewPlan {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepperNewPlan.withButtons {
  margin-left: -32px;
  margin-right: -32px;
}

.stepperNewPlanLabel {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  padding-bottom: 14px;
}

.stepper {
  margin-right: -30px;
}

.currentQuotaDisplayWrapper {
  padding: 8.4px 14px;
  border-radius: 5px;
  color: var(--DISABLED-FONT-COLOR);
  background: var(--DISABLED-BORDER-COLOR);
}

.basePlanQuotaLabel {
  margin-top: 8px;
  color: var(--DISABLED-FONT-COLOR);
  height: 20px;
  margin-bottom: -26px;
}

.arrowWrap {
  display: inline-flex;
  align-items: center;
  height: 40px;
  align-self: flex-end;
}

.arrow {
  display: inline-flex;
  align-items: center;
  --color: #000;
  rotate: 180deg;
  align-self: flex-end;
  width: 11px;
  height: auto;
  margin-bottom: 16px;
  transform: translateX(-20px);
}
