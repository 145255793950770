.group {
  padding-left: 32px;
}

.group .groupBox {
  padding-top: 7px;
}

.group.selected .groupBox {
  padding: 17px 14px 0;
  background-color: #f6f5f5;
  border-radius: 9px;
  position: relative;
}

.group.selected.last {
  padding-bottom: 20px;
}

.remove {
  position: absolute;
  right: 20px;
  top: 14px;
  width: 20px;
  height: 20px;
  z-index: 2;
}

.controls {
  display: flex;
  align-items: flex-start;
  flex-flow: row nowrap;
  margin: 0 0 7px;
}

.input {
  flex: 1 0 auto;
}

.conditionDivider {
  height: 1px;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-top: 1px solid var(--DISABLED-BORDER-COLOR);
  margin: 21px 0;
}

.conditionDivider .label {
  line-height: 1;
  display: inline-block;
  padding: 5px 10px;
  background-color: var(--GRAY-LIGHT);
  border-radius: 20px;
  color: var(--DISABLED-FONT-COLOR);
  font-weight: 500;
  font-size: 12px;
  border: 8px solid #fff;
}

.multipleActions {
  margin-top: -7px;
  margin-left: 33px;
  margin-bottom: 14px;
  --color: var(--BLACK);
}

.multipleActions :global(.button) {
  white-space: nowrap;
  flex-flow: nowrap;
  font-size: 12px !important;
}

.multipleActions svg {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}

.fieldHeader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  min-height: 42px;
  cursor: pointer;
}

.fieldHeader .label {
  font-weight: 500;
  cursor: pointer;
}

.field.hidden {
  display: none;
}

.field .check {
  flex: 0 0 auto;
  margin-right: 14px;
  cursor: pointer;
}

.collapsible {
  transition: all 400ms;
}

.collapse {
  flex: 0 0 auto;
  margin-left: auto;
  cursor: pointer;
  transform: translateY(2px);
}

.curtain {
  transition: all 500ms;
}

.field .check :global(.ui.checkbox) {
  margin-bottom: 0 !important;
}

.field .check :global(.ui.checkbox:before) {
  margin-right: 0 !important;
}

.fieldHeader:hover .label {
  color: var(--PRIMARY-BLUE);
}

.errors {
  margin-left: 32px;
}
