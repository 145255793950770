.radio:global(.ui) {
  display: flex !important;
  align-items: center;
  height: 20px;
  margin: 0 0 14px !important;
}

.radio:before {
  display: inline-flex;

  width: 20px;
  height: 20px;
  line-height: 0;
  content: "";
  border-radius: 20px;
  border: 1px solid var(--PRIMARY-BLUE);
  flex: 0 0 20px;
  margin: 0 14px 0 0;
}

.radio.checked:before {
  background: url("./assets/radio-check.svg") 50% 50% no-repeat;
}

.radio:global(.ui) label {
  display: inline-block;
  width: auto !important;
  flex: 1 0 auto;
  font-weight: 500;
  padding: 0 !important;
}

.radio:global(.ui) label:after,
.radio:global(.ui) label:before {
  display: none;
}
