.input {
  height: 33px;
  min-height: 0;
  border: 1px solid #eaeaea;
  outline: none;
  border-radius: 5px;
  padding: 0.6em 1em;
  color: #3c4257;
  flex: 1 0 auto;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  margin: 0;
  max-width: 100%;
  background: #fff;
}
.input:focus {
  border: 1px solid #94c2fb;
  box-shadow: 0 0 1px 3px #b8dbff;
}
.input.fluid {
  display: block;
  width: 100%;
  flex-grow: 1;
  flex-basis: 100%;
}
