.checkbox:global(.ui) {
  display: flex !important;
  align-items: center;
  /*width: 20px;*/
  height: 20px;
  margin: 0 0 14px !important;
}

.checkbox:before {
  display: inline-flex;

  width: 20px;
  height: 20px;
  line-height: 0;
  content: "";
  border-radius: 6px;
  border: 1px solid var(--PRIMARY-BLUE);
  flex: 0 0 20px;
  margin: 0 14px 0 0;
  cursor: pointer;
}

.checkbox.checked:before {
  background: var(--PRIMARY-BLUE) url("./assets/checkbox-check.svg") 4px 5px
    no-repeat;
}

.checkbox:global(.ui) label {
  display: inline-block;
  width: auto !important;
  flex: 1 0 auto;
  padding: 0 !important;
}

.checkbox:global(.ui) label:after,
.checkbox:global(.ui) label:before {
  display: none;
}
