.input {
  margin-left: 21px;
}

.input.radio :global(.checkbox.ui.ui.ui label:before),
.input.radio :global(.checkbox.ui.ui.ui label:after),
.input.radio :global(.checkbox.ui.ui.ui input:checked:focus ~ label:before),
.input.radio :global(.checkbox.ui.ui.ui input:checked:focus ~ label:after) {
  background-color: transparent !important;
}
