.container {
  display: grid;
  place-content: center;
}
.container > .content {
  width: 640px;
  text-align: center;
}

.container > .content > .header {
  font-weight: 500;
  font-size: 28px;
}

.container > .content > .content {
  padding: 24px 0px;
}
.link {
  color: var(--PRIMARY-BLUE) !important;
  font-weight: 500;
}
.link:hover {
  color: var(--LINK-HOVER) !important;
  cursor: pointer;
}
.title {
  text-transform: capitalize;
}
