.group {
  border-bottom: 1px solid #d8d8d8;
  margin-left: -32px;
  margin-right: -32px;
  padding-left: 32px;
  padding-right: 32px;
}

.header {
  min-height: 68px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.header .text {
  font-size: 16px;
  font-weight: 500;
  margin-right: 14px;
}

.header .collapse {
  margin-left: auto;
  align-self: flex-start;
  margin-top: 26px;
}

.fields {
  padding-bottom: 14px;
}

.header .text {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header .text .counter {
  font-size: 12px;
  color: var(--GRAY-MID);
  margin-left: 14px;
}

.collapsible {
  transition: all 400ms;
}

.groupCurtain {
  transition: all 600ms;
}
