.condition {
  display: flex;
  flex-direction: column;
}

.condition .checkbox {
  margin-right: 7px;
}

.option {
  margin: 0 0 7px !important;
}

.input {
  margin: 0 0 7px;
}

.input :global(.dropdown.ui.ui.ui) {
  padding-right: 0 !important;
}
