.expand {
  --image: url("../../../assets/images/icons/expand-more.svg");
  --width: 8.57px;
  --height: 16.29px;
  padding: 3.43px 7.71px 4.29px;
  cursor: pointer;
  background-position: center !important;
  transition: transform 0.5s;
}

.expand.close {
}
.expand.open {
  transform: rotate(90deg);
}
